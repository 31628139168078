
/* select search css starts*/
.css-1aya2g8{
    border-color: unset !important;
    border: 0;
    border-width: 0 !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    font-size: 14px;
    min-height: calc(2.75rem + 2px) !important;
}
.css-2o5izw:hover{
    border-color: unset !important;
}
.css-2o5izw{
    font-size: 14px !important;
    min-height: calc(2.75rem + 2px) !important;
    box-shadow: unset !important;
}
.css-va7pk8{
    color: #8898aa !important;
}
.css-1492t68{
    color: #8898aa !important;
}
/* select search css ends*/
.btn_trash{
    background: no-repeat;
    border: 0;
    font-size: 20px;
}
.txtdis{
    width: 78px;
    float: right;
    height: 35px;
}
body{
    background-color: lightskyblue;
}
.card-body{
    cursor: pointer;
}
.footercss{
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: lightskyblue;
    padding: 1.5rem 0;
}
.bg_headercss{
   background-color: #87cefa;
}
.navbar-vertical .navbar-nav .nav-link.active:before{
    border-left: 2px solid #87cefa;
}
.page-item.active .page-link {
    background-color: #87cefa;
    border-color: #87cefa; }

    .navbar-vertical .navbar-nav .nav-link{
        font-size: 17px;
    }
.new_line {
  white-space: pre-line;
}
.login_title{
    font-size: 22px;
    font-weight: bold;
    display: block;
}
.login_card{
    padding-top: 5%;
    padding-bottom: 0%;
    border-bottom: 0;
}
.input-group-text{
    background-color: #e8f0fe !important;
}
.qtytxt{
    width : 100%;
    height : calc(1.75rem + 2px);
}
.distxt{
    display : inline-block;
    width : 70%;
    height : calc(1.75rem + 2px);
}
.product_hr{
    margin-top: 5px;
    margin-bottom: 10px;
}
.amt_div{
    border-left: 1px solid #eee;
}
.del_section{
    display: flex;
}
.product_lbl{
    color: #525f7f;
    font-size: 0.875rem;
    font-weight: 600;
}
.alert_card{
    padding: 8px;
}

.show_loader{
    z-index: 10000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);display : block;
    }
    .go_btn{
        font-size : 16px;
    }
    .go_to_icon{
        border-radius: 100%;
    padding: 2px 4px;
    color: #f5365c;
    font-size: 14px;
    background-color: #fff;
    }
    .page_not_img{
        width : 100%
    }
    .disable_root{
        opacity :0.5 ;
        pointer-events: none;
    }
.stock_card_body{
    padding: 0.8rem;
}
.stock_card_data{
    font-weight: 600;
}
.stock_card_divider{
    margin: 1% 0 1% 0;
    border-color: #86cbf6;
}
.stock_card_metadata{
    color: #f2365c;
}
.stock_card_label{
float:right;
font-size: 0.8em;
}
.stock_header{
color: #f2365c;
}
.preview_img {
    width: 100%;
    height: 280px;
}
.preview_img:hover ~ .file_lbl{
    display : block !important; 
}
/* .preview_img:hover ~ .remove{
    opacity: 1;
} */
.remove{
    background-color: #00000096;
    border: 0;
    font-size: 30px;
    padding: 0 2px;
    border-radius: 5px;
    position: absolute;
    top: 102px;
    right: 23px;
    height: 38px;
    width: 40px;
    color : #ed1a1a;
    cursor: pointer;
    /* opacity:0; */
    /* display: none; */
}
select[disabled] .select_input{
    background: #eee !important;
}
select[disabled]{
    background: #eee !important;
    pointer-events: none;
    touch-action: none;
}
.overlay{
    width: 93%;
    height: 63%;
    position: absolute;
    overflow: hidden;
    top: 94px;
    left: 15px;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.file_lbl{
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);
    display : none;
    padding: 10px 0;
    background-color: #0000006b;
    color: #fff;
    font-size: 27px;
    width: 90%;
    text-align: center;
}
.signcan{
    touch-action: none;
    background-color: #fff;
    width: 100%;
    height: 150px;
}
.sign_clearbtn{
    float: right;
    background-color: unset;
    color: #f5365c;
    padding: 5px 0;
    line-height: 1;
}
.error_cls{
    color: red;
    font-size: 13px;
}
.sign_clearbtn:hover, .sign_clearbtn:focus{
    background-color: unset;
    box-shadow : unset;
}
.valve_divider{
    border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.logout_icon {
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    color: #252462;
}
.desk_logo {
    display: block;
    width: 36px;
    height: 40px !important;
    float: left;
    margin-right: 6%;
}
.head_div{
    width: 100%;
    display: flex;
}
.auth_logo{
    width : 11%;
    margin-right: 3%;
}
.navbar-horizontal .navbar-brand{
    text-transform: capitalize;
    font-size: 1.25rem;
    font-size: 24px;
}
.job_title {
    font-size: 20px;
    font-weight: 700;
}
.mob_logo {
    display: none;
}
.col_media {
    flex-basis: 0;
    flex-grow: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 18px;
    padding-top : 2px;
}
.col_div_media {
    flex-basis: 0;
    flex-grow: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col_div {
    flex-basis: 0;
    flex-grow: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.back_btn{
    float: right;
    border-radius: 100px;
    padding: 5px 8px;
}
.back_icon{
    position: relative;
    top: 2px;
}
.chk_col{
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 7px;
}
.valve_rd_div {
    flex-basis: 0;
    flex-grow: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.cam_icon{
    font-size: 50px;
}
.popup-content{
    width: 95% !important;
}
.signPad{
    touch-action: none;
    background-color: #fff;
    width: 100%;
    height: 220px;
    border: 1px solid #ddd;
    color : #145394;
}
.signimg{
    width : 100%;
    height : 200px;
    background-color: #fff;
}
.modal_close{
    padding: 0;
    background-color: unset;
}
.modal_close:hover , .modal_close:focus{
    box-shadow: unset;
}
.test_select{
    background-color: #e8f0fe !important;
    padding : 12.5px 7px;
    /* padding: 0 7px; */
    height: auto;
    cursor: pointer;
}
.select_input{
    padding: 0 4px;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}
.btn_text{
    display:contents;
}

@media only screen and (max-width: 540px){
    .next_prev_btn{
        padding: 9px 12px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .btn_formgrp{
        display: inline-grid;
    }
    .btn_formgrp button{
        margin-bottom: 9px;
    }
    .btn_save{
        padding: 9px 12px;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .btn_lefticon {
        padding: 9px 12px;
        margin-right: 0 !important;
    }
    .btn_righticon {
        padding: 9px 12px;
        margin-left: 0 !important;
    }
    .preview_img{
        width : 100%;
        height: auto;
    }
    .col_media{
        padding-right: 0;
    }
}
@media only screen and (max-width: 586px) and (min-width: 320px){
    .qty_col{
        flex: 0 0 37.66667%;
        max-width: 37.66667%;
        font-size: 14px;
    }
    .amt_col{
        flex: 0 0 62.33333%;
        max-width: 62.33333%;
        font-size: 14px;
        padding-left: 0;
    }
    .tot_section{
        font-size: 14px;
    }
    .desk_logo{
        display : none;
    }
    .mob_logo {
        display: block;
        height: 35px !important;
    }
    .valve_rd_div {
        padding-right: 12px;
    }
    .head_div{
        width: unset;
        display: flex;
    }
    .col_div_media{
        flex-basis: unset;
        flex-grow: unset;
        padding-right: 15px;
        padding-left: 26px;
    }
}
@media only screen and (max-width:667px) and (min-width:375px){
    .head_div{
        width: unset;
        display: flex;
    }
    .col_div_media{
        padding-right: 8px;
        padding-left: 8px;
        flex-basis: 0;
        flex-grow: 0;
    }

}
@media only screen and (max-width:736px) and (min-width:414px) {
    .head_div{
        width: unset;
        display: flex;
    }
    .col_div_media{
        padding-right: 8px;
        padding-left: 8px;
        flex-basis: 0;
        flex-grow: 0;
    }
}
@media only screen and (max-width:770px) and (min-width:766px) and (orientation:portrait){
    .btn_save{
        font-size: 13px;
    }
    .btn_text{
        font-size: 13px;
    }
    .preview_img{
        width : 100%;
    }
}